import React from 'react'
import styles from './inputText.module.scss'

interface InputTextProps {
    label: string
    placeholder: string
    errorText?: string
}

const InputText:React.FC<InputTextProps> = ({ label, placeholder, errorText, ...input }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <label htmlFor={label}
          className={styles.label}>
          {label}
        </label>
        <input type="text" placeholder={placeholder} name="nickname" className={styles.input} {...input}/>
        <div className={styles.errorText}>{errorText}</div>
      </div>
      <div className={styles.error}/>
    </div>
  )
}
export default InputText

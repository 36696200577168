const animateScrollToElement = (id: string): void => {
  const elToScroll = document.getElementById(id)
  console.log('elToScroll', elToScroll)
  if (elToScroll) {
    elToScroll.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    })
  }
}

export default animateScrollToElement

export const sizes = {
  mobile: [0, 767],
  tablet: [768, 1279],
  l: [1280, 1439],
  xl: [1440]
}

export const getMediaRule = (label: Array<number>, withoutMediaKey = false) => {
  if (label[1]) {
    return `${!withoutMediaKey ? '@media ' : ''}(min-width: ${
            label[0]
        }px) and (max-width: ${label[1]}px)`
  }
  return `${!withoutMediaKey ? '@media ' : ''}(min-width: ${label[0]}px)`
}

import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import backend from 'i18next-xhr-backend'

import LanguageDetector from 'i18next-browser-languagedetector'
export const i18n = i18next
  .use(backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    debug:
            process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',

    interpolation: {
      escapeValue: false
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    }
  })

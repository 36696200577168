import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import Home from './pages/Home'
import './locales/i18n'
import { BrowserRouter } from 'react-router-dom'
import { MediaQueriesProvider } from './MediaQueriesProvider'
import { sizes } from './styles'

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={false}>
      <MediaQueriesProvider queries={sizes}>
        <BrowserRouter>
          <Home />
        </BrowserRouter>
      </MediaQueriesProvider>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root')
)

import React, { useCallback, useMemo, useState } from 'react'
import styles from './contactUs.module.scss'
import { useTranslation, Trans } from 'react-i18next'
import InputText from '../../ui/InputText'
import Button from '../../ui/Button'
import { Form, Field } from 'react-final-form'
import { formValidator } from '../../utils'
import * as yup from 'yup'

const ContactUs:React.FC = () => {
  const { t } = useTranslation()

  const [isShowSubmitted, showIsShowSubmitted] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)
  const setSubmitted = useCallback(() => {
    showIsShowSubmitted(true)
    setSubmitting(false)
  }, [])

  const onSubmitHandler = useCallback(() => {
    setSubmitting(true)
    setTimeout(() => {
      setSubmitted()
    }, 1000)
  }, [])

  const schema = useMemo(() => (yup.object({
    email: yup
      .string()
      .email(t('validation.email.invalid'))
      .required(t('validation.email.empty'))

  })), [])
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div>
          <div className={styles.title} id="contact">
            {t('contactUs.title')}
          </div>
          <div className={styles.subTitle}>
            {t('contactUs.description')}
          </div>
        </div>
        <div className={styles.formContainer}>
          <Form
            onSubmit={onSubmitHandler}
            validate={formValidator(schema)}
            initialValues={{}}
            validateOnBlur={true}
            render={({ handleSubmit, form, submitting, errors }) => (
              <form
                onSubmit={handleSubmit}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    form.blur('email')
                  }
                }}
              >
                <Field name="email">
                  {({ input, meta }) => (
                    <InputText label="Email" placeholder={t('forms.email.placeholder')} {...input}
                      errorText={
                        (meta.touched && meta.error)
                          ? meta.error : undefined
                      } />
                  )}</Field>
                <Button type="submit" disabled={isSubmitting} text={t('send')}/>
              </form>
            )} />
          <div className={styles.submitted}>
            {isShowSubmitted && <Trans>
              {t('validation.success.submitted')}
            </Trans>}

          </div>

        </div>
      </div>
      <div className={styles.bluredBackground}/>
    </div>
  )
}

export default ContactUs

import React from 'react'
import Header from '../../features/Header'
import Main from '../../features/Main'
import OurServices from '../../features/OurServices'
import ContactUs from '../../features/ContactUs'
import Footer from '../../features/Footer'

const Home: React.FC = () => {
  return (
    <div>
      <Header/>
      <Main/>
      <OurServices/>
      <ContactUs/>
      <Footer/>
    </div>
  )
}

export default Home

import React from 'react'
import styles from './ourServices.module.scss'
import { useTranslation, Trans } from 'react-i18next'
import HireDevelopers from './HireDevelopers'
import {
  website,
  deployment,
  api, system,
  security,
  backup,
  support,
  development,
  maintenance,
  apiIntegrations,
  design,
  quality,
  dataMigrations,
  slaSupport,
  customerManagement,
  paymentProcess,
  iot
} from '../../assets/serviceIcons/assets'
import laptop from '../../assets/laptop1.png'
import laptop2 from '../../assets/laptop2.png'
import laptop3 from '../../assets/laptop3.png'
import mobile from '../../assets/mobile.png'
import desktop from '../../assets/desktop.png'
import { useBreakpoint } from '../../MediaQueriesProvider'

const OurServices:React.FC = () => {
  const { t } = useTranslation()
  const breakpoints = useBreakpoint()
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.title} id="ourServices">
          {t('ourServices.title')}
        </div>
        <div className={styles.subTitle}>{t('ourServices.description')}</div>
        <div className={styles.paragraph}>
          {t('ourServices.experiencedDevelopers')}
        </div>
        <div className={styles.section}>
          <img className={styles.serviceImage} src={laptop} alt="laptop"/>
          <div>
            <div className={styles.serviceItem}>
              <div className={styles.icon}>
                <img src={website} alt="website"/>
              </div>
              <div>
                <div className={styles.cardTitle}>
                  {t('ourServices.websiteCreation.title')}
                </div>
                <div className={styles.cardText}>
                  {t('ourServices.websiteCreation.description')}
                </div>
              </div>
            </div>
            <div className={styles.serviceItem}>
              <div className={styles.icon}>
                <img src={deployment} alt="deployment"/>
              </div>
              <div>
                <div className={styles.cardTitle}>
                  {t('ourServices.deployment.title')}
                </div>
                <div className={styles.cardText}>
                  {t('ourServices.deployment.description')}
                </div>
              </div>
            </div>
            <div className={styles.serviceItem}>
              <div className={styles.icon}>
                <img src={api} alt="api"/>
              </div>
              <div>
                <div className={styles.cardTitle}>
                  {t('ourServices.api.title')}
                </div>
                <div className={styles.cardText}>
                  {t('ourServices.api.description')}
                </div>
              </div>
            </div>
            <div className={styles.serviceItem}>
              <div className={styles.icon}>
                <img src={system} alt="system"/>
              </div>
              <div>
                <div className={styles.cardTitle}>
                  {t('ourServices.system.title')}
                </div>
                <div className={styles.cardText}>
                  {t('ourServices.system.description')}
                </div>
              </div>
            </div>
          </div>
        </div>
        {breakpoints.mobile || breakpoints.tablet ? (
          <div className={styles.section}>
            <img className={styles.serviceImage} src={laptop2} alt="laptop"/>
            <div>
              <div className={styles.serviceItem}>
                <div className={styles.icon}>
                  <img src={security} alt="security"/>
                </div>
                <div>
                  <div className={styles.cardTitle}>
                    {t('ourServices.security.title')}
                  </div>
                  <div className={styles.cardText}>
                    {t('ourServices.security.description')}
                  </div>
                </div>
              </div>
              <div className={styles.serviceItem}>
                <div className={styles.icon}>
                  <img src={backup} alt="backup"/>
                </div>
                <div>
                  <div className={styles.cardTitle}>
                    {t('ourServices.backup.title')}
                  </div>
                  <div className={styles.cardText}>
                    {t('ourServices.backup.description')}
                  </div>
                </div>
              </div>
              <div className={styles.serviceItem}>
                <div className={styles.icon}>
                  <img src={support} alt="support"/>
                </div>
                <div>
                  <div className={styles.cardTitle}>
                    {t('ourServices.support.title')}
                  </div>
                  <div className={styles.cardText}>
                    {t('ourServices.support.description')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
          : (
            <div className={styles.section}>
              <div>
                <div className={styles.serviceItem}>
                  <div className={styles.icon}>
                    <img src={security} alt="security"/>
                  </div>
                  <div>
                    <div className={styles.cardTitle}>
                      {t('ourServices.security.title')}
                    </div>
                    <div className={styles.cardText}>
                      {t('ourServices.security.description')}
                    </div>
                  </div>
                </div>
                <div className={styles.serviceItem}>
                  <div className={styles.icon}>
                    <img src={backup} alt="backup"/>
                  </div>
                  <div>
                    <div className={styles.cardTitle}>
                      {t('ourServices.backup.title')}
                    </div>
                    <div className={styles.cardText}>
                      {t('ourServices.backup.description')}
                    </div>
                  </div>
                </div>
                <div className={styles.serviceItem}>
                  <div className={styles.icon}>
                    <img src={support} alt="support"/>
                  </div>
                  <div>
                    <div className={styles.cardTitle}>
                      {t('ourServices.support.title')}
                    </div>
                    <div className={styles.cardText}>
                      {t('ourServices.support.description')}
                    </div>
                  </div>
                </div>
              </div>
              <img className={styles.serviceImage} src={laptop2} alt="laptop"/>
            </div>
          )

        }
        <div className={styles.paragraph}>
          {t('ourServices.productLifeCycle')}
        </div>
        <div className={styles.section}>
          <img className={styles.serviceImage} src={mobile} alt="mobile"/>
          <div>
            <div className={styles.serviceItem}>
              <div className={styles.icon}>
                <img src={development} alt="development"/>
              </div>
              <div>
                <div className={styles.cardTitle}>
                  {t('ourServices.development.title')}
                </div>
                <div className={styles.cardText}>
                  {t('ourServices.development.description')}
                </div>
              </div>
            </div>
            <div className={styles.serviceItem}>
              <div className={styles.icon}>
                <img src={maintenance} alt="maintenance"/>
              </div>
              <div>
                <div className={styles.cardTitle}>
                  {t('ourServices.maintenance.title')}
                </div>
                <div className={styles.cardText}>
                  {t('ourServices.maintenance.description')}
                </div>
              </div>
            </div>
            <div className={styles.serviceItem}>
              <div className={styles.icon}>
                <img src={apiIntegrations} alt="apiIntegrations"/>
              </div>
              <div>
                <div className={styles.cardTitle}>
                  {t('ourServices.apiIntegrations.title')}
                </div>
                <div className={styles.cardText}>
                  {t('ourServices.apiIntegrations.description')}
                </div>
              </div>
            </div>
            <div className={styles.serviceItem}>
              <div className={styles.icon}>
                <img src={design} alt="design"/>
              </div>
              <div>
                <div className={styles.cardTitle}>
                  {t('ourServices.design.title')}
                </div>
                <div className={styles.cardText}>
                  {t('ourServices.design.description')}
                </div>
              </div>
            </div>
          </div>
        </div>
        {breakpoints.mobile || breakpoints.tablet ? (
          <div className={styles.section}>
            <img className={styles.serviceImage} src={laptop3} alt="laptop"/>
            <div>
              <div className={styles.serviceItem}>
                <div className={styles.icon}>
                  <img src={quality} alt="quality"/>
                </div>
                <div>
                  <div className={styles.cardTitle}>
                    {t('ourServices.quality.title')}
                  </div>
                  <div className={styles.cardText}>
                    {t('ourServices.quality.description')}
                  </div>
                </div>
              </div>
              <div className={styles.serviceItem}>
                <div className={styles.icon}>
                  <img src={dataMigrations} alt="dataMigrations"/>
                </div>
                <div>
                  <div className={styles.cardTitle}>
                    {t('ourServices.dataMigrations.title')}
                  </div>
                  <div className={styles.cardText}>
                    {t('ourServices.dataMigrations.description')}
                  </div>
                </div>
              </div>
              <div className={styles.serviceItem}>
                <div className={styles.icon}>
                  <img src={slaSupport} alt="sla support"/>
                </div>
                <div>
                  <div className={styles.cardTitle}>
                    {t('ourServices.support.title')}
                  </div>
                  <div className={styles.cardText}>
                    {t('ourServices.support.description')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.section}>
            <div>
              <div className={styles.serviceItem}>
                <div className={styles.icon}>
                  <img src={quality} alt="quality"/>
                </div>
                <div>
                  <div className={styles.cardTitle}>
                    {t('ourServices.quality.title')}
                  </div>
                  <div className={styles.cardText}>
                    {t('ourServices.quality.description')}
                  </div>
                </div>
              </div>
              <div className={styles.serviceItem}>
                <div className={styles.icon}>
                  <img src={dataMigrations} alt="dataMigrations"/>
                </div>
                <div>
                  <div className={styles.cardTitle}>
                    {t('ourServices.dataMigrations.title')}
                  </div>
                  <div className={styles.cardText}>
                    {t('ourServices.dataMigrations.description')}
                  </div>
                </div>
              </div>
              <div className={styles.serviceItem}>
                <div className={styles.icon}>
                  <img src={slaSupport} alt="sla support"/>
                </div>
                <div>
                  <div className={styles.cardTitle}>
                    {t('ourServices.support.title')}
                  </div>
                  <div className={styles.cardText}>
                    {t('ourServices.support.description')}
                  </div>
                </div>
              </div>
            </div>
            <img className={styles.serviceImage} src={laptop3} alt="laptop"/>
          </div>
        )}
        <HireDevelopers/>
        <div className={styles.paragraph}>
          {t('ourServices.customSolutions')}
        </div>
        <div className={styles.section}>
          <img className={styles.serviceImage} src={desktop} alt="desktop"/>
          <div>
            <div className={styles.serviceItem}>
              <div className={styles.icon}>
                <img src={customerManagement} alt="customerManagement"/>
              </div>
              <div>
                <div className={styles.cardTitle}>
                  <Trans>
                    {t('ourServices.customerManagement.title')}
                  </Trans>
                </div>
                <div className={styles.cardText}>
                  {t('ourServices.customerManagement.description')}
                </div>
              </div>
            </div>
            <div className={styles.serviceItem}>
              <div className={styles.icon}>
                <img src={api} alt="api"/>
              </div>
              <div>
                <div className={styles.cardTitle}>
                  <Trans>
                    {t('ourServices.enterprise.title')}
                  </Trans>

                </div>
                <div className={styles.cardText}>
                  {t('ourServices.enterprise.description')}
                </div>
              </div>
            </div>
            <div className={styles.serviceItem}>
              <div className={styles.icon}>
                <img src={paymentProcess} alt="payment process"/>
              </div>
              <div>
                <div className={styles.cardTitle}>
                  {t('ourServices.paymentProcess.title')}
                </div>
                <div className={styles.cardText}>
                  {t('ourServices.paymentProcess.description')}
                </div>
              </div>
            </div>
            <div className={styles.serviceItem}>
              <div className={styles.icon}>
                <img src={iot} alt="iot"/>
              </div>
              <div>
                <div className={styles.cardTitle}>
                  {t('ourServices.iot.title')}
                </div>
                <div className={styles.cardText}>
                  {t('ourServices.iot.description')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurServices

import React from 'react'
import styles from './main.module.scss'
import { useTranslation, Trans } from 'react-i18next'
import Button from '../../ui/Button'
import { animateScrollToElement } from '../../hooks'

const Main:React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.wrapper} id="about">
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.mainText}>
            <Trans>
              {t('main.bigText')}
            </Trans>
          </div>
          <div className={styles.text}>
            {t('main.secondText')}
          </div>
          <div className={styles.buttonContainer}>
            <Button text={t('getStarted')} onClick={() => { animateScrollToElement('contact') }}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Main

import React from 'react'
import styles from './footer.module.scss'
import logo from '../../assets/logo.svg'
import { useBreakpoint } from '../../MediaQueriesProvider'

const Footer:React.FC = () => {
  const breakpoints = useBreakpoint()
  return (
    <div className={styles.container}>
      <img src={logo} alt="logo"/>
      {breakpoints.mobile || breakpoints.tablet ? (<>
        <div className={styles.text}>info@l2p.tech   +357 25 101010</div>
        <div className={styles.text}>Loucaides Building, 2nd floor, office 21,1 Arch. Kyprianou & Ayiou Andreou Corner, Limassol, 3036, Cyprus</div>
        <div className={styles.text}>L2P.tech ©2022</div>
      </>) : (
        <>
          <div className={styles.text}>
                    info@l2p.tech   +357 25 101010
            <br/>
                    Loucaides Building, 2nd floor, office 21,1 Arch. Kyprianou & Ayiou Andreou Corner, Limassol, 3036, Cyprus
          </div>
          <div className={styles.text}>L2P.tech ©2022</div>
        </>
      )}

    </div>
  )
}

export default Footer

import React from 'react'
import styles from './mobile.module.scss'
import closeIcon from '../../../assets/close.svg'
import { useTranslation } from 'react-i18next'

const MobileMenu:React.FC<{setCloseMobileMenu: () => void}> = ({ setCloseMobileMenu }) => {
  const { t } = useTranslation()
  return (
    <div className={styles.container}>
      <div className={styles.menuContainer}>
        <div className={styles.menuItem}>
          <a href="#about" onClick={setCloseMobileMenu}>
            {t('header.about')}
          </a>
        </div>
        <div className={styles.menuItem}>
          <a href="#ourServices" onClick={setCloseMobileMenu}>
            {t('header.ourServices')}
          </a>
        </div>
        <div className={styles.menuItem}>
          <a href="#hire" onClick={setCloseMobileMenu}>
            {t('header.hire')}
          </a>
        </div>
        <div className={styles.menuItem}>
          <a href="#contact" onClick={setCloseMobileMenu}>
            {t('header.contact')}
          </a>
        </div>
      </div>
      <button className={styles.close} onClick={setCloseMobileMenu}>
        <img src={closeIcon} alt="close"/>
      </button>

    </div>
  )
}

export default MobileMenu

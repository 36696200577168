import { useCallback, useLayoutEffect } from 'react'

const useAnimateScrolling = () => {
  const animateScroll = useCallback(
    anchor => e => {
      e.preventDefault()

      const blockID = anchor.getAttribute('href').substr(1)

      document.getElementById(blockID)?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      })
    },
    []
  )

  useLayoutEffect(() => {
    const anchors = document.querySelectorAll('a[href^="#"]')

    for (const anchor of anchors) {
      anchor.addEventListener('click', animateScroll(anchor))
    }

    return () => {
      for (const anchor of anchors) {
        anchor.removeEventListener('click', animateScroll(anchor))
      }
    }
  }, [animateScroll])
}

export default useAnimateScrolling

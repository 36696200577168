import React from 'react'
import styles from './button.module.scss'

interface ButtonProps {
  disabled?:boolean;
  text: string;
  onClick?: () => void;
  type: 'submit' | 'button'
}

const Button:React.FC<ButtonProps> = ({
  text,
  onClick,
  type = 'button',
  disabled
}) => {
  return <button disabled={disabled} className={styles.button} onClick={onClick} type={type}>{text}</button>
}

export default Button

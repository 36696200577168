import React, { useCallback, useState } from 'react'
import logo from '../../assets/logo.svg'
import styles from './header.module.scss'
import { useTranslation } from 'react-i18next'
import { useBreakpoint } from '../../MediaQueriesProvider'
import burgerMenu from '../../assets/burgerMenu.svg'
import { useAnimateScrolling } from '../../hooks'
import MobileMenu from './MobileMenu'

const Header: React.FC = () => {
  const { t } = useTranslation()
  const breakpoints = useBreakpoint()
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false)

  const setOpenMobileMenu = useCallback(() => {
    setIsOpenMobileMenu(true)
  }, [])

  const setCloseMobileMenu = useCallback(() => {
    setIsOpenMobileMenu(false)
  }, [])

  useAnimateScrolling()
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <img src={logo} alt="l2p.tech"/>
        {!breakpoints.mobile && !breakpoints.tablet && <div className={styles.menuContainer}>
          <div className={styles.menuItem}>
            <a href="#about">
              {t('header.about')}
            </a>
          </div>
          <div className={styles.menuItem}>
            <a href="#ourServices">
              {t('header.ourServices')}
            </a>
          </div>
          <div className={styles.menuItem}>
            <a href="#hire">
              {t('header.hire')}
            </a>
          </div>
          <div className={styles.menuItem}>
            <a href="#contact">
              {t('header.contact')}
            </a>
          </div>
        </div>}
        {(breakpoints.mobile || breakpoints.tablet) && (
          <img src={burgerMenu} className={styles.burgerMenu} alt="menu" onClick={setOpenMobileMenu}/>
        )}
      </div>
      {isOpenMobileMenu && <MobileMenu setCloseMobileMenu={setCloseMobileMenu}/>}

    </div>
  )
}

export default Header

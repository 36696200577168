import React from 'react'
import styles from './hireDevelopers.module.scss'
import { useTranslation, Trans } from 'react-i18next'
import tablet from '../../../assets/tablet.png'

const HireDevelopers:React.FC = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title} id="hire">
          <Trans>
            {t('hireDevelopers.title')}
          </Trans></div>
        <div className={styles.description}>{t('hireDevelopers.description')}</div>
        <div className={styles.techStackContainer}>
          <div>
            <div className={styles.paragraph}>{t('hireDevelopers.programLang')}</div>
            <div className={styles.stackItem}>* Java</div>
            <div className={styles.stackItem}>* JavaScript</div>
            <div className={styles.stackItem}>* Python</div>
            <div className={styles.stackItem}>* C#</div>
          </div>
          <div>
            <div className={styles.paragraph}>{t('hireDevelopers.frameworks')}</div>
            <div className={styles.stackItem}>* Angular</div>
            <div className={styles.stackItem}>* Ruby on Rails</div>
            <div className={styles.stackItem}>* ASP .NET</div>
            <div className={styles.stackItem}>* React</div>
          </div>
        </div>
      </div>
      <img src={tablet} alt="tablet"/>

    </div>
  )
}

export default HireDevelopers
